@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.clickable{
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    /* me7tageen ne3raf el opacity text-decoration-color: ; */

}
.markdown > blockquote {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
  
  text-align: center;


} 

.markdown > h1 {
  --tw-text-opacity: 1; color: rgb(85 0 29 / var(--tw-text-opacity));
  font-size: 1.25rem;
  line-height: 1.75rem;
  /* margin-bottom: 1rem; */
  /* margin-top: 1.25rem; */
  text-align: center;
}

.markdown > h3 {
  --tw-text-opacity: 1; color: rgb(0 29 85 / var(--tw-text-opacity));
  /* margin-bottom: 0.7rem; */

}

.markdown > p {
  font-size: 1.125rem/* 18px */;
  line-height: 1.9rem/* 28px */;
  /* margin-bottom: 0.7rem; */

}

.markdown > p + h3 {
    margin-bottom: 0.5em;
    margin-top: 1rem;
}

.markdown > h1 + h3 {
  margin-bottom: 0.5em;
  margin-top: 1rem;

}

.markdown > blockquote + h3 {
  margin-bottom: 0.5em;
  margin-top: 1rem;
}


.markdown > p + p {
  margin-bottom: 0.5rem;
}

.markdown > h1 + h1 {
  margin-top: 0.25rem;
}

.markdown > p + h1 {
  margin-top: 1.25rem;
}

.markdown > h1 + p {
  margin-top: 1.25rem;
}

.markdown > blockquote + p {
  margin-top: 1.25rem;
}

/* .pnum_box{
  position: absolute;
  width:50px;
  height:2rem;
  margin-top: -4px
} */
.form-control {
  padding-right: 0.3rem;
  width: 6rem; 
  border-width: 1px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.2rem /* 6px */;
  --tw-border-opacity: 1; border-color: rgb(0 0 0 / var(--tw-border-opacity));

}
.arabic {
  font-family: "Tajawal", sans-serif;
  }

  html {
    font-size: 1rem;
    line-height: 1.7rem;
   }


